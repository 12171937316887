<template>
  <div v-loading="loading" element-loading-text="数据加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
    <div class="cart-header">
      <div class="cart-info">
        <div class="cart-title-container">
          <div class="cart-title">
            <span v-if="!isEditing">{{ cartInfo.name }}</span>
            <el-input v-else v-model="editingName" size="small" placeholder="请输入选号车名称" class="edit-name-input" ref="nameInput"></el-input>
          </div>
          <div class="cart-title-actions">
            <el-button v-if="!isEditing" type="text" icon="el-icon-edit" @click="startEditing">重命名</el-button>
            <template v-else>
              <el-button type="text" @click="cancelEditing">取消</el-button>
              <el-button type="text" @click="saveCartName">保存</el-button>
            </template>
          </div>
        </div>
        <div class="cart-operations">
          <el-button type="primary" @click="exportCart" :disabled="totalCount === 0">导出选号车</el-button>
          <el-button type="danger" @click="confirmDeleteCart" :disabled="!cartInfo.cart_id">删除选号车</el-button>
        </div>
      </div>
      <div class="cart-stats">
        <div class="stat-item">
          <span class="stat-label">总达人数</span>
          <span class="stat-value">{{ totalCount }}</span>
        </div>
        <div class="stat-item">
          <span class="stat-label">创建时间</span>
          <span class="stat-value">{{ cartInfo.created_at }}</span>
        </div>
        <div class="stat-item">
          <span class="stat-label">更新时间</span>
          <span class="stat-value">{{ cartInfo.updated_at }}</span>
        </div>
      </div>
    </div>

    <!-- 移除平台分组标签页，只保留全部达人列表 -->
    <div class="cart-table-container">
      <el-table :data="cartItems" style="width: 100%" v-loading="tableLoading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="nick_name" label="达人信息" width="230">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <el-image 
                style="min-width: 40px; width: 40px; height: 40px; border-radius: 50%; margin-right: 10px; cursor: pointer;" 
                :src="scope.row.avatar_uri" 
                fit="cover"
                @click="goToKolDetail(scope.row.star_id, true)">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div class="user-info">
                <div class="nickname-text" @click="goToKolDetail(scope.row.star_id, true)" style="cursor: pointer;">
                  {{ scope.row.nick_name }}
                </div>
                <div class="sub-info">
                  {{ scope.row.gender_text }}
                  {{ (scope.row.gender_text) && (scope.row.province || scope.row.city) ? '|' : '' }}
                  {{ scope.row.province }}{{ scope.row.city && scope.row.city !== scope.row.province ? scope.row.city : '' }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="platform_name" label="平台" width="80"></el-table-column>
        <el-table-column prop="follower" label="粉丝数">
          <template slot-scope="scope">
            {{ formatNumber(scope.row.follower) }}
          </template>
        </el-table-column>
        <el-table-column prop="price_type" label="价格类型" >
          <template slot-scope="scope">
            {{ getPriceTypeText(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" >
          <template slot-scope="scope">
            {{ getSelectedPrice(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="added_at" label="添加时间" ></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="danger" @click="removeKol(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加预估方案总价显示 -->
    <div class="cart-total-price" v-if="totalCount > 0">
      <div class="price-container">
        <div class="total-price-label">预估方案总价</div>
        <div class="total-price-value">¥ {{ formatTotalPrice(totalPrice) }}</div>
        <div class="total-price-chinese">{{ convertToChinese(totalPrice) }}</div>
      </div>
    </div>

    <!-- 空状态提示 -->
    <div v-if="totalCount === 0" class="empty-state">
      <i class="el-icon-shopping-cart-2"></i>
      <p>选号车中还没有达人，请前往达人列表添加</p>
      <el-button type="primary" @click="goToKolList">去添加达人</el-button>
    </div>
  </div>
</template>

<script>
import { getCartDetail, updateCartName, removeKolFromCart, deleteCart, exportCartData } from '@/api'

export default {
  name: 'CartDetail',
  data () {
    return {
      loading: false,
      tableLoading: false,
      cartId: '',
      cartInfo: {},
      cartItems: [],
      groupedItems: {},
      totalCount: 0,
      isEditing: false,
      editingName: ''
    }
  },
  computed: {
    // 计算预估方案总价
    totalPrice () {
      let total = 0;
      this.cartItems.forEach(item => {
        if (item.price_type && item[item.price_type]) {
          // 确保转换为数字
          const price = parseFloat(item[item.price_type]);
          if (!isNaN(price)) {
            total += price;
          }
        }
      });
      return total;
    }
  },
  created () {
    // 从路由参数获取选号车ID
    this.cartId = this.$route.query.cart_id
    if (this.cartId) {
      this.fetchCartDetail()
    } else {
      this.$message.error('选号车ID不能为空')
      this.$router.push('/kol/list')
    }
  },
  methods: {
    async fetchCartDetail () {
      this.loading = true
      this.tableLoading = true
      try {
        const res = await getCartDetail({ cart_id: this.cartId })
        if (res.code === 200 && res.data) {
          this.cartInfo = res.data.cart_info || {}
          this.cartItems = res.data.items || []
          this.totalCount = res.data.total_count || 0
        } else {
          this.$message.error(res.sub_msg || '获取选号车详情失败')
        }
      } catch (error) {
        console.error('获取选号车详情失败:', error)
        this.$message.error('获取选号车详情失败，请稍后重试')
      } finally {
        this.loading = false
        this.tableLoading = false
      }
    },
    formatNumber (num) {
      if (!num && num !== 0) return '-';

      // 处理负数情况
      const isNegative = num < 0;
      const absNum = Math.abs(num);

      let formattedNum;
      if (absNum >= 10000) {
        formattedNum = (absNum / 10000).toFixed(1) + 'w';
      } else {
        formattedNum = absNum.toString();
      }

      // 如果是负数，添加负号
      return isNegative ? '-' + formattedNum : formattedNum;
    },
    getPriceTypeText (item) {
      if (!item.price_type) return '-'

      if (item.plat === 4 || item.platform_id === 4) { // B站
        const priceTypeMap = {
          'price_1_20': '定制视频',
          'price_20_60': '植入视频',
          'price_60': '直发动态',
          'price_180': '转发动态'
        }
        return priceTypeMap[item.price_type] || '-'
      } else if (item.plat === 2 || item.platform_id === 2) { // 小红书
        const priceTypeMap = {
          'price_1_20': '图文报价',
          'price_20_60': '视频报价'
        }
        return priceTypeMap[item.price_type] || '-'
      } else if (item.plat === 5 || item.platform_id === 5) { // 微信视频号
        const priceTypeMap = {
          'price_1_20': '短视频报价',
          'price_20_60': '长视频报价'
        }
        return priceTypeMap[item.price_type] || '-'
      } else if (item.plat === 6 || item.platform_id === 6) { // 微信公众号
        const priceTypeMap = {
          'price_1_20': '原创文章',
          'price_20_60': '转载文章'
        }
        return priceTypeMap[item.price_type] || '-'
      } else if (item.plat === 7 || item.platform_id === 7) { // 微博
        const priceTypeMap = {
          'price_1_20': '普通微博',
          'price_20_60': '超话推广',
          'price_60': '视频微博'
        }
        return priceTypeMap[item.price_type] || '-'
      } else { // 抖音、快手
        const priceTypeMap = {
          'price_1_20': '1-20s视频价格',
          'price_20_60': '20-60s视频价格',
          'price_60': '60s以上视频价格'
        }
        return priceTypeMap[item.price_type] || '-'
      }
    },
    getSelectedPrice (item) {
      if (!item.price_type) return '-'
      const price = item[item.price_type]
      return price ? `¥${price}` : '-'
    },
    startEditing () {
      this.isEditing = true
      this.editingName = this.cartInfo.name
      this.$nextTick(() => {
        this.$refs.nameInput.focus()
      })
    },
    cancelEditing () {
      this.isEditing = false
    },
    async saveCartName () {
      if (!this.editingName.trim()) {
        this.$message.warning('选号车名称不能为空')
        return
      }

      try {
        const res = await updateCartName({
          cart_id: this.cartId,
          name: this.editingName.trim()
        })

        if (res.code === 200) {
          this.$message.success('选号车名称已更新')
          this.cartInfo.name = this.editingName.trim()
          this.isEditing = false
        } else {
          this.$message.error(res.sub_msg || '更新选号车名称失败')
        }
      } catch (error) {
        console.error('更新选号车名称失败:', error)
        this.$message.error('更新选号车名称失败，请稍后重试')
      }
    },
    async removeKol (kol) {
      this.$confirm(`确定要从选号车中移除达人"${kol.nick_name}"吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          const res = await removeKolFromCart({
            cart_id: this.cartId,
            star_id: kol.star_id
          })

          if (res.code === 200) {
            this.$message.success('达人已从选号车中移除')
            // 重新获取选号车详情
            this.fetchCartDetail()
          } else {
            this.$message.error(res.sub_msg || '移除达人失败')
          }
        } catch (error) {
          console.error('移除达人失败:', error)
          this.$message.error('移除达人失败，请稍后重试')
        }
      }).catch(() => { })
    },
    confirmDeleteCart () {
      this.$confirm(`确定要删除选号车"${this.cartInfo.name}"吗？删除后无法恢复！`, '警告', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        try {
          const res = await deleteCart({ cart_id: this.cartId })

          if (res.code === 200) {
            this.$message.success('选号车已删除')
            // 返回达人列表页
            this.$router.push('/kol/list')
          } else {
            this.$message.error(res.sub_msg || '删除选号车失败')
          }
        } catch (error) {
          console.error('删除选号车失败:', error)
          this.$message.error('删除选号车失败，请稍后重试')
        }
      }).catch(() => { })
    },
    async exportCart () {
      this.loading = true
      try {
        const response = await exportCartData({ cart_id: this.cartId })

        // 检查响应类型
        if (response.data.type && response.data.type.includes('json')) {
          const reader = new FileReader()
          reader.onload = () => {
            const error = JSON.parse(reader.result)
            this.$message.error(error.sub_msg || '导出失败')
          }
          reader.readAsText(response.data)
          return
        }

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url

        // 从响应头获取文件名并解码，去掉UTF-8前缀
        const contentDisposition = response.headers['content-disposition']
        let filename = `${this.cartInfo.name}_${new Date().toISOString().replace(/[:.]/g, '_')}.xlsx`
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
          if (filenameMatch && filenameMatch[1]) {
            filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''))
              .replace(/^UTF-8/, '') // 去掉UTF-8前缀
          }
        }

        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)

        this.$message.success('选号车导出成功')
      } catch (error) {
        console.error('选号车导出失败:', error)
        this.$message.error(error.response?.data?.sub_msg || '导出失败，请稍后重试')
      } finally {
        this.loading = false
      }
    },
    goToKolList () {
      this.$router.push('/kol/list')
    },
    goToKolDetail (starId, newWindow = false) {
      if (starId) {
        const url = `/kol/detail?star_id=${starId}`
        if (newWindow) {
          window.open(url, '_blank')
        } else {
          this.$router.push(url)
        }
      } else {
        this.$message.warning('达人ID不存在，无法查看详情')
      }
    },
    formatTotalPrice (price) {
      return price.toLocaleString('zh-CN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    convertToChinese (price) {
      if (!price || price === 0) return '零元';

      // 数字对应的中文
      const chineseNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      // 整数部分单位
      const intUnits = ['', '拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟', '万'];

      // 处理整数部分
      const intPart = Math.floor(price);
      const intPartStr = intPart.toString();
      let chineseStr = '';

      // 处理整数部分
      if (intPart === 0) {
        chineseStr = '零';
      } else {
        for (let i = 0; i < intPartStr.length; i++) {
          const digit = parseInt(intPartStr[i]);
          const unit = intUnits[intPartStr.length - 1 - i];

          // 处理零的情况
          if (digit === 0) {
            // 当前数字是0，且不是最后一位，且下一位不是0，才加"零"
            if (i < intPartStr.length - 1 && intPartStr[i + 1] !== '0') {
              chineseStr += '零';
            }
            // 处理万和亿的情况
            if (unit === '万' || unit === '亿') {
              // 检查前面是否已经有"零"
              if (chineseStr.charAt(chineseStr.length - 1) !== '零') {
                chineseStr += unit;
              }
            }
          } else {
            chineseStr += chineseNums[digit] + unit;
          }
        }
      }

      // 添加"元"
      chineseStr += '元整';

      // 处理小数部分（这里简化处理，不考虑角分）

      return chineseStr;
    }
  }
}
</script>

<style scoped>
.cart-header {
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.cart-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.cart-title-container {
  display: flex;
  align-items: center;
}

.cart-title {
  font-size: 20px;
  font-weight: bold;
  color: #303133;
  margin-right: 10px;
}

.edit-name-input {
  width: 300px;
}

.cart-title-actions {
  display: flex;
}

.cart-stats {
  display: flex;
  background-color: #f5f7fa;
  border-radius: 4px;
  padding: 12px 20px;
}

.stat-item {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-label {
  color: #909399;
  font-size: 12px;
  margin-bottom: 5px;
}

.stat-value {
  color: #303133;
  font-weight: 500;
  font-size: 16px;
}

.cart-operations {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cart-table-container {
  margin: 0 20px 20px;
  border-radius: 4px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.nickname-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  font-weight: 500;
}

.sub-info {
  font-size: 12px;
  color: #909399;
  margin-top: 4px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  color: #909399;
}

.empty-state i {
  font-size: 60px;
  margin-bottom: 20px;
}

.empty-state p {
  font-size: 16px;
  margin-bottom: 20px;
}

.cart-total-price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px;
  border-radius: 4px;
  margin: 20px;
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.total-price-label {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
  margin-bottom: 5px;
}

.total-price-value {
  font-size: 24px;
  font-weight: bold;
  color: #409EFF;
  margin-bottom: 5px;
}

.total-price-chinese {
  font-size: 14px;
  color: #606266;
  font-weight: 500;
}

/* 修复列表样式 */
.el-table {
  margin-bottom: 20px;
}

.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>